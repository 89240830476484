<template>
  <Dialog :common-dialog="caseTrustDialog" :dialog-width="dialogWidth" dense>
    <template v-slot:title>
      <h1 class="text-truncate custom-page-title m-0">
        Upload Signed Case Trust
      </h1>
    </template>
    <template v-slot:body>
      <v-container class="px-10">
        <table width="100%" style="table-layout: fixed">
          <tr>
            <td width="50%">
              <v-file-input
                :disabled="form_loading"
                :loading="form_loading"
                ref="select-file"
                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                placeholder="Click here to select file"
                label="Click here to select file"
                prepend-icon=""
                color="cyan"
                solo
                flat
                class="my-4"
                v-model="attachment"
                dense
                v-on:change="on_file_change($event)"
                hide-details
              ></v-file-input>
            </td>
            <td width="50%">
              <v-text-field
                :disabled="form_loading"
                :loading="form_loading"
                placeholder="File Name"
                label="File Name"
                dense
                solo
                flat
                color="cyan"
                v-model="attachment_name"
                hide-details
                class="has-delete-outer-icon"
                append-outer-icon="mdi-delete"
                v-on:click:append-outer="remove_row()"
                :suffix="get_file_extension()"
              ></v-text-field>
            </td>
          </tr>
        </table>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="form_loading"
        :loading="form_loading"
        class="mx-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="update_case_trust()"
        >Submit
      </v-btn>
      <v-btn
        :disabled="form_loading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
        >Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { UPLOAD, PATCH } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin],
  props: {
    caseTrustDialog: {
      type: Boolean,
      default: false,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Dialog,
  },
  watch: {
    caseTrustDialog(param) {
      if (param) {
        this.$nextTick(() => {
          this.T = null;
          this.$refs["select-file"].reset();
        });
      }
    },
  },
  data() {
    return {
      attachment: null,
      attachment_name: null,
      attachment_url: null,
      attachment_type: null,
      form_loading: false,
    };
  },
  methods: {
    update_case_trust() {
      if (!this.attachment_url) {
        return false;
      }
      this.form_loading = true;
      this.$store
        .dispatch(PATCH, {
          url: `quotation/${this.quotationId}/signed-case-trust-pdf`,
          data: { attachment_url: this.attachment_url },
        })
        .then(() => {
          this.$emit("success", true);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    remove_row() {
      this.$nextTick(() => {
        this.attachment = null;
        this.$refs["select-file"].reset();
        this.select_file();
      });
    },
    update_file_name() {
      const file = this.attachment;
      this.attachment_name = null;
      this.attachment_type = null;
      if (file) {
        this.attachment_name = file.name.split(".").slice(0, -1).join(".");
        this.attachment_type = file.name.split(".").pop();
      }
    },
    get_file_extension() {
      if (this.attachment_type) {
        return `.${this.attachment_type}`;
      }
      return null;
    },
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      this.update_file_name();
      if (file) {
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.form_loading = true;
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.path");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.form_loading = false;
          });
      }
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
